.author{
    float: right;

}

.author > p , .author > h6, .author > i {
margin:0
}
 
 .userInfo > div{
    margin: 1%;
 
}
.textArea{
    margin: 1%; 
}


.cta{
    margin: 1%;
    float: right;
}

.comment{
    margin-bottom:5%
}

.comment>p , .comment>h6{
margin: 0;
}

.comments_section{
    margin-bottom:  50px;
}


.line{
    border-top: 10px solid rgba(0, 0, 0, 0.500);
}

@media (min-width: 640px){
    .userInfo{
        display: flex;
     
    }
    
    
  
    
    
    .userInfo > div{
       
        width: 49%;
    }

   
    
}



