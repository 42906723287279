.section{
    background-color: #008cb7;
    padding-bottom: 20px;
    padding-top: 20px;
    
   
}


.promotionWrapper{
    margin-top: 100px;
    margin-bottom: 100px;
}


.header{
    text-align: center;
}

.header > h1{
    color: white;
    /* margin-bottom: 60px; */
    margin-top: 0px;
   
    font-size:xx-large;
    
}

.card{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.card > h3, .card > h4{

    color: white;
    margin: 5px;
    

}


.card > h3{
    font-size:x-large;
}


.card > h4{
    font-size:large;
}


.img{
    height: 150px;
    width: 150px !important;
    border-radius: 75px;
}