/*! CSS Used from: https://www.ndlschool.com/plugins/bootstrap/css/bootstrap.min.css */
.section{
    display:block;
    z-index: 1;
    margin-top: -120px;
}
 a{
    background-color:transparent;
}
 a:active,a:hover{
    outline:0;
}
 @media print{
     *,:after,:before{
        color:#000!important;
        text-shadow:none!important;
        background:0 0!important;
        -webkit-box-shadow:none!important;
        box-shadow:none!important;
    }
     a,a:visited{
        text-decoration:underline;
    }
     a[href]:after{
        content:" (" attr(href) ")";
    }
     a[href^="#"]:after{
        content:"";
    }
}
 *{
    -webkit-box-sizing:border-box;
    -moz-box-sizing:border-box;
    box-sizing:border-box;
}
 :after,:before{
    -webkit-box-sizing:border-box;
    -moz-box-sizing:border-box;
    box-sizing:border-box;
}
 a{
    color:#337ab7;
    text-decoration:none;
}
 a:focus,a:hover{
    color:#23527c;
    text-decoration:underline;
}
 a:focus{
    outline:5px auto -webkit-focus-ring-color;
    outline-offset:-2px;
}
 .container{
    padding-right:15px;
    padding-left:15px;
    margin-right:auto;
    margin-left:auto;
}
 @media (min-width:768px){
     .container{
        width:750px;
    }
}
 @media (min-width:992px){
     .container{
        width:970px;
    }
}
 @media (min-width:1200px){
     .container{
        width:1170px;
    }
}
 .row{
    margin-right:-15px;
    margin-left:-15px;
}
 .col_sm_3{
    position:relative;
    min-height:1px;
    padding-right:15px;
    padding-left:15px;
}
 @media (min-width:768px){
     .col_sm_3{
        float:left;
    }
     .col_sm_3{
        width:25%;
    }
}
 .clearfix:after,.clearfix:before,.container:after,.container:before,.row:after,.row:before{
    display:table;
    content:" ";
}
 .clearfix:after,.container:after,.row:after{
    clear:both;
}
 @media (max-width:767px){
     .hidden_xs{
        display:none!important;
    }
}
/*! CSS Used from: https://www.ndlschool.com/plugins/font-awesome/css/font-awesome.min.css */
 .fa{
    display:inline-block;
    font:normal normal normal 14px/1 FontAwesome;
    font-size:inherit;
    text-rendering:auto;
    -webkit-font-smoothing:antialiased;
    -moz-osx-font-smoothing:grayscale;
}
 /* .fa_picture_o:before{
    content:"\f03e";
}
 .fa_calendar:before{
    content:"\f073";
}
 .fa_chevron_down:before{
    content:"\f078";
}
 .fa_users:before{
    content:"\f0c0";
}
 .fa_file_text_o:before{
    content:"\f0f6";
} */
/*! CSS Used from: https://www.ndlschool.com/css/style.css */
 *{
    outline:0;
    margin:0;
    padding:0;
}
 .container{
    width:100%;
}
 @media (min-width: 480px){
     .container{
        width:450px;
    }
}
 @media (min-width: 320px){
     .container{
        width:100%;
    }
}
 @media (min-width: 768px){
     .container{
        width:750px;
    }
}
 @media (min-width: 992px){
     .container{
        width:970px;
    }
}
 @media (min-width: 1200px){
     .container{
        width:1170px;
    }
}
 a{
    color:#ea7066;
    text-decoration:none!important;
    transition:all 0.3s ease-in-out;
}
 a:hover{
    outline:0;
}
 a:focus{
    outline:0;
}
 .bg_color_1{
    /* background-color:#f0c24b!important; */
    background: linear-gradient(to top left, #f0c24b 0%, #ffff00 100%) !important;
}
 .border_color_1{
    border-color:#f0c24b!important;
}
 .bg_color_2{
    /* background-color:#b5d56a!important; */
    background: linear-gradient(to bottom, #b5d56a 0%, #90c80e 100%)!important;
}
 .border_color_2{
    border-color:#b5d56a!important;
}
 .bg_color_3{
    /* background-color:#ea7066!important; */
    background: linear-gradient(to bottom, #ea7066 0%, #f52a1a 100%) !important;
}
 .border_color_3{
    border-color:#ea7066!important;
}
 .bg_color_4{
    /* background-color:#84bed6!important; */
    background: linear-gradient(to bottom, #84bed6 0%, #3399ff 100%) !important;
}
 .border_color_4{
    border-color:#84bed6!important;
}
 .linkIcon,.locateArrow{
    display:block;
    position:absolute;
    left:50%;
    margin-left:-50px;
    transition:all 0.3s ease-in-out;
}
 .linkSection{
    width:100%;
    z-index:500;
}
 @media (min-width: 320px){
     .linkSection{
        position:relative;
    }
}
 @media (min-width: 768px){
     .linkSection{
        margin-top:90px;
    }
}
 .sectionLink{
    width:100%;
    height:190px;
    display:block;
    border-radius:13px;
    text-align:center;
    transition:all 0.3s ease-in-out;
    box-shadow:0 4px 0 rgba(0, 0, 0, 0.1);
}
 .sectionLink .linkIcon{
    width:100px;
    height:100px;
    border-radius:100%;
    background-color:#ffffff;
    border-width:6px;
    border-style:solid;
    top:-50px;
    font-size:30px;
    line-height:100px;
    color:#666666;
}
 .sectionLink .linkText{
    line-height:190px;
    display:block;
    /* font-family:'Dosis', sans-serif; */
    font-family: 'League Spartan', sans-serif;
    font-size:32px;
    font-weight:400;
    color:#ffffff;
    letter-spacing:1px;
}
 .sectionLink .locateArrow{
    font-size:18px;
    color:#ffffff;
    bottom:27px;
    margin-left:-10px;
    -ms-filter:"progid:DXImageTransform.Microsoft.Alpha(Opacity=$value)";
    filter:alpha(opacity=0);
    -moz-opacity:0;
    -khtml-opacity:0;
    opacity:0;
}
 @media (min-width: 320px){
     .sectionLink{
        margin-bottom:75px;
    }
}
 @media (min-width: 768px){
     .sectionLink{
        margin-bottom:0;
    }
}
 .sectionLink:hover .border_color_1{
    color:#f0c24b;
}
 .sectionLink:hover .border_color_2{
    color:#b5d56a;
}
 .sectionLink:hover .border_color_3{
    color:#ea7066;
}
 .sectionLink:hover .border_color_4{
    color:#84bed6;
}
 .sectionLink:hover .locateArrow{
    -ms-filter:"progid:DXImageTransform.Microsoft.Alpha(Opacity=$value)";
    filter:alpha(opacity=1);
    -moz-opacity:1;
    -khtml-opacity:1;
    opacity:1;
}
/*! CSS Used from: https://www.ndlschool.com/css/default.css */
 .sectionLink:hover .border_color_1{
    color:#f0c24b;
}
 .bg_color_1{
    /* background-color:#f0c24b!important; */
    background: linear-gradient(to top left, #f0c24b 0%, #ffff00 100%) !important;
}
 .border_color_1{
    border-color:#f0c24b!important;
}
 .sectionLink:hover .border_color_2{
    color:#b5d56a;
}
 .bg_color_2{
    /* background-color:#b5d56a!important; */
    background: linear-gradient(to bottom, #b5d56a 0%, #90c80e 100%)!important;

}
 .border_color_2{
    border-color:#b5d56a!important;
}
 a,.sectionLink:hover .border_color_3{
    color:#ea7066;
}
 .bg_color_3{
    /* background-color:#ea7066!important; */
    background: linear-gradient(to bottom, #ea7066 0%, #f52a1a 100%) !important;

}
 .border_color_3{
    border-color:#ea7066!important;
}
 .sectionLink:hover .border_color_4{
    color:#84bed6;
}
 .bg_color_4{
    /* background-color:#84bed6!important; */
    background: linear-gradient(to bottom, #84bed6 0%, #3399ff 100%) !important;
}
 .border_color_4{
    border-color:#84bed6!important;
}
/*! CSS Used fontfaces */
 @font-face{
    font-family:'FontAwesome';
    src:url('https://www.ndlschool.com/plugins/font-awesome/fonts/fontawesome-webfont.eot?v=4.5.0');
    src:url('https://www.ndlschool.com/plugins/font-awesome/fonts/fontawesome-webfont.eot#iefix&v=4.5.0') format('embedded-opentype'),url('https://www.ndlschool.com/plugins/font-awesome/fonts/fontawesome-webfont.woff2?v=4.5.0') format('woff2'),url('https://www.ndlschool.com/plugins/font-awesome/fonts/fontawesome-webfont.woff?v=4.5.0') format('woff'),url('https://www.ndlschool.com/plugins/font-awesome/fonts/fontawesome-webfont.ttf?v=4.5.0') format('truetype'),url('https://www.ndlschool.com/plugins/font-awesome/fonts/fontawesome-webfont.svg?v=4.5.0#fontawesomeregular') format('svg');
    font-weight:normal;
    font-style:normal;
}
 @font-face{
    font-family:'Dosis';
    font-style:normal;
    font-weight:300;
    src:url(https://fonts.gstatic.com/s/dosis/v27/HhyaU5sn9vOmLzlnC_W6EQ.woff2) format('woff2');
    unicode-range:U+0102-0103, U+0110-0111, U+0128-0129, U+0168-0169, U+01A0-01A1, U+01AF-01B0, U+1EA0-1EF9, U+20AB;
}
 @font-face{
    font-family:'Dosis';
    font-style:normal;
    font-weight:300;
    src:url(https://fonts.gstatic.com/s/dosis/v27/HhyaU5sn9vOmLzlmC_W6EQ.woff2) format('woff2');
    unicode-range:U+0100-024F, U+0259, U+1E00-1EFF, U+2020, U+20A0-20AB, U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF;
}
 @font-face{
    font-family:'Dosis';
    font-style:normal;
    font-weight:300;
    src:url(https://fonts.gstatic.com/s/dosis/v27/HhyaU5sn9vOmLzloC_U.woff2) format('woff2');
    unicode-range:U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}
 @font-face{
    font-family:'Dosis';
    font-style:normal;
    font-weight:400;
    src:url(https://fonts.gstatic.com/s/dosis/v27/HhyaU5sn9vOmLzlnC_W6EQ.woff2) format('woff2');
    unicode-range:U+0102-0103, U+0110-0111, U+0128-0129, U+0168-0169, U+01A0-01A1, U+01AF-01B0, U+1EA0-1EF9, U+20AB;
}
 @font-face{
    font-family:'Dosis';
    font-style:normal;
    font-weight:400;
    src:url(https://fonts.gstatic.com/s/dosis/v27/HhyaU5sn9vOmLzlmC_W6EQ.woff2) format('woff2');
    unicode-range:U+0100-024F, U+0259, U+1E00-1EFF, U+2020, U+20A0-20AB, U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF;
}
 @font-face{
    font-family:'Dosis';
    font-style:normal;
    font-weight:400;
    src:url(https://fonts.gstatic.com/s/dosis/v27/HhyaU5sn9vOmLzloC_U.woff2) format('woff2');
    unicode-range:U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}
 @font-face{
    font-family:'Dosis';
    font-style:normal;
    font-weight:600;
    src:url(https://fonts.gstatic.com/s/dosis/v27/HhyaU5sn9vOmLzlnC_W6EQ.woff2) format('woff2');
    unicode-range:U+0102-0103, U+0110-0111, U+0128-0129, U+0168-0169, U+01A0-01A1, U+01AF-01B0, U+1EA0-1EF9, U+20AB;
}
 @font-face{
    font-family:'Dosis';
    font-style:normal;
    font-weight:600;
    src:url(https://fonts.gstatic.com/s/dosis/v27/HhyaU5sn9vOmLzlmC_W6EQ.woff2) format('woff2');
    unicode-range:U+0100-024F, U+0259, U+1E00-1EFF, U+2020, U+20A0-20AB, U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF;
}
 @font-face{
    font-family:'Dosis';
    font-style:normal;
    font-weight:600;
    src:url(https://fonts.gstatic.com/s/dosis/v27/HhyaU5sn9vOmLzloC_U.woff2) format('woff2');
    unicode-range:U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}
 @font-face{
    font-family:'Dosis';
    font-style:normal;
    font-weight:700;
    src:url(https://fonts.gstatic.com/s/dosis/v27/HhyaU5sn9vOmLzlnC_W6EQ.woff2) format('woff2');
    unicode-range:U+0102-0103, U+0110-0111, U+0128-0129, U+0168-0169, U+01A0-01A1, U+01AF-01B0, U+1EA0-1EF9, U+20AB;
}
 @font-face{
    font-family:'Dosis';
    font-style:normal;
    font-weight:700;
    src:url(https://fonts.gstatic.com/s/dosis/v27/HhyaU5sn9vOmLzlmC_W6EQ.woff2) format('woff2');
    unicode-range:U+0100-024F, U+0259, U+1E00-1EFF, U+2020, U+20A0-20AB, U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF;
}
 @font-face{
    font-family:'Dosis';
    font-style:normal;
    font-weight:700;
    src:url(https://fonts.gstatic.com/s/dosis/v27/HhyaU5sn9vOmLzloC_U.woff2) format('woff2');
    unicode-range:U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}
